import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  onSnapshot, 
  orderBy, 
  limit, 
  doc, 
  updateDoc, 
  getDoc, 
  Timestamp, 
  arrayUnion,
  writeBatch
} from 'firebase/firestore';
import { db } from '../firebase';
import { getAuth } from 'firebase/auth';

// Create notification context
export const NotificationContext = createContext();

// Custom hook to use the notification context
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

// Notification provider component
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unseenCount, setUnseenCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notificationSettings, setNotificationSettings] = useState({
    clockInOut: true,
    violations: true,
    contactSubmissions: true,
    missedShifts: true,
    employeeRequests: true
  });
  
  const auth = getAuth();
  const user = auth.currentUser;

  // Fetch notification settings
  useEffect(() => {
    const fetchNotificationSettings = async () => {
      if (!user) return;
      
      try {
        const docRef = doc(db, 'customers', user.uid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          // Use existing settings or defaults
          setNotificationSettings({
            clockInOut: data.notifyClockInOut !== undefined ? data.notifyClockInOut : true,
            violations: data.notifyViolations !== undefined ? data.notifyViolations : true,
            contactSubmissions: data.notifyContactSubmissions !== undefined ? data.notifyContactSubmissions : true,
            missedShifts: data.notifyMissedShifts !== undefined ? data.notifyMissedShifts : true,
            employeeRequests: data.notifyEmployeeRequests !== undefined ? data.notifyEmployeeRequests : true
          });
        }
      } catch (err) {
        console.error('Error fetching notification settings:', err);
        setError(err.message);
      }
    };

    fetchNotificationSettings();
  }, [user]);

  // Update notification settings
  const updateNotificationSettings = async (newSettings) => {
    if (!user) return;

    try {
      const settingsToUpdate = {
        notifyClockInOut: newSettings.clockInOut,
        notifyViolations: newSettings.violations,
        notifyContactSubmissions: newSettings.contactSubmissions,
        notifyMissedShifts: newSettings.missedShifts,
        notifyEmployeeRequests: newSettings.employeeRequests
      };

      // Update in Firestore
      await updateDoc(doc(db, 'customers', user.uid), settingsToUpdate);
      
      // Update local state
      setNotificationSettings(newSettings);
      
      return { success: true };
    } catch (err) {
      console.error('Error updating notification settings:', err);
      setError(err.message);
      return { success: false, error: err.message };
    }
  };

  // Fetch notifications
  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }

    setLoading(true);
    
    // Create a notifications collection reference
    const notificationsRef = collection(db, 'customers', user.uid, 'notifications');
    const q = query(
      notificationsRef,
      orderBy('timestamp', 'desc'),
      limit(50)
    );
    
    // Set up a real-time listener
    const unsubscribe = onSnapshot(q, (snapshot) => {
      try {
        const notificationsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date()
        }));
        
        setNotifications(notificationsData);
        
        // Calculate unread and unseen counts
        const unread = notificationsData.filter(n => !n.read).length;
        const unseen = notificationsData.filter(n => !n.seen).length;
        setUnreadCount(unread);
        setUnseenCount(unseen);
        
        setLoading(false);
      } catch (err) {
        console.error('Error processing notifications:', err);
        setError(err.message);
        setLoading(false);
      }
    }, (err) => {
      console.error('Error fetching notifications:', err);
      setError(err.message);
      setLoading(false);
    });
    
    // Cleanup subscription
    return () => unsubscribe();
  }, [user]);

  // Check for new notifications based on logs
  useEffect(() => {
    if (!user || !notificationSettings) return;
    
    // Set up an interval to check for new notifications every minute
    const checkNewNotifications = async () => {
      try {
        // Only check for notifications that are enabled in settings
        const nowTimestamp = Timestamp.now();
        const oneHourAgo = new Date(nowTimestamp.toDate().getTime() - 60 * 60 * 1000);
        
        // Check for violations
        if (notificationSettings.violations) {
          await checkViolations(oneHourAgo);
        }
        
        // Check for contact submissions
        if (notificationSettings.contactSubmissions) {
          await checkContactSubmissions(oneHourAgo);
        }
        
        // Check for clock ins/outs
        if (notificationSettings.clockInOut) {
          await checkClockInOuts(oneHourAgo);
        }
        
        // Check for missed shifts
        if (notificationSettings.missedShifts) {
          await checkMissedShifts();
        }
        
        // Check for employee requests
        if (notificationSettings.employeeRequests) {
          await checkEmployeeRequests(oneHourAgo);
        }
      } catch (err) {
        console.error('Error checking for new notifications:', err);
      }
    };
    
    // Run immediately and then set interval
    checkNewNotifications();
    const intervalId = setInterval(checkNewNotifications, 60000); // Every minute
    
    return () => clearInterval(intervalId);
  }, [user, notificationSettings]);

  // Function to check for new violations
  const checkViolations = async (since) => {
    if (!user) return;
    
    try {
      // Get all shifts
      const shiftsRef = collection(db, 'customers', user.uid, 'shifts');
      const shiftsSnapshot = await getDocs(shiftsRef);
      
      for (const shiftDoc of shiftsSnapshot.docs) {
        // Get violations for this shift
        const violationsRef = collection(db, 'customers', user.uid, 'shifts', shiftDoc.id, 'violations');
        const q = query(
          violationsRef,
          where('approved', '==', false),
          where('createdAt', '>=', since)
        );
        
        const violationsSnapshot = await getDocs(q);
        
        // For each unapproved violation, create a notification if it doesn't exist
        for (const violationDoc of violationsSnapshot.docs) {
          const violationData = violationDoc.data();
          
          // Check if a notification already exists for this violation
          const existingNotification = notifications.find(
            n => n.type === 'violation' && n.violationId === violationDoc.id
          );
          
          if (!existingNotification) {
            // Create a new notification
            await createNotification({
              type: 'violation',
              title: 'New Violation Report',
              message: `Violation reported at ${violationData.communityName}, Unit ${violationData.unitNumber}`,
              timestamp: violationData.createdAt,
              read: false,
              seen: false,
              routeUrl: '/violations',
              violationId: violationDoc.id,
              shiftId: shiftDoc.id
            });
          }
        }
      }
    } catch (err) {
      console.error('Error checking for violations:', err);
    }
  };

  // Function to check for new contact submissions
  const checkContactSubmissions = async (since) => {
    if (!user) return;
    
    try {
      // Get contacts with status "new"
      const contactsRef = collection(db, 'customers', user.uid, 'contacts');
      const q = query(
        contactsRef,
        where('status', '==', 'new'),
        where('timestamp', '>=', since)
      );
      
      const contactsSnapshot = await getDocs(q);
      
      // For each new contact, create a notification if it doesn't exist
      for (const contactDoc of contactsSnapshot.docs) {
        const contactData = contactDoc.data();
        
        // Check if a notification already exists for this contact
        const existingNotification = notifications.find(
          n => n.type === 'contact' && n.contactId === contactDoc.id
        );
        
        if (!existingNotification) {
          // Create a new notification
          await createNotification({
            type: 'contact',
            title: 'New Contact Submission',
            message: `${contactData.name} sent an inquiry about ${contactData.communityName}`,
            timestamp: contactData.timestamp,
            read: false,
            seen: false,
            routeUrl: `/communities/${contactData.communityName}`,
            contactId: contactDoc.id
          });
        }
      }
    } catch (err) {
      console.error('Error checking for contact submissions:', err);
    }
  };

  // Function to check for new clock ins/outs
  const checkClockInOuts = async (since) => {
    if (!user) return;
    
    try {
      // Get logs for clock in/out events
      const logsRef = collection(db, 'customers', user.uid, 'logs');
      const q = query(
        logsRef,
        where('type', 'in', ['clockIn', 'clockOut']),
        where('timestamp', '>=', since)
      );
      
      const logsSnapshot = await getDocs(q);
      
      // For each log, create a notification if it doesn't exist
      for (const logDoc of logsSnapshot.docs) {
        const logData = logDoc.data();
        
        // Check if a notification already exists for this log
        const existingNotification = notifications.find(
          n => n.type === 'clockInOut' && n.logId === logDoc.id
        );
        
        if (!existingNotification) {
          const isClockIn = logData.type === 'clockIn';
          const title = isClockIn ? 'Employee Clocked In' : 'Employee Clocked Out';
          const message = isClockIn
            ? `${logData.agentName || 'Employee'} clocked in at ${logData.communityName}`
            : `${logData.agentName || 'Employee'} clocked out at ${logData.communityName}`;
          
          // Create a new notification
          await createNotification({
            type: 'clockInOut',
            title,
            message,
            timestamp: logData.timestamp,
            read: false,
            seen: false,
            routeUrl: '/clock-in-outs',
            logId: logDoc.id
          });
        }
      }
    } catch (err) {
      console.error('Error checking for clock ins/outs:', err);
    }
  };

  // Function to check for missed shifts
  const checkMissedShifts = async () => {
    if (!user) return;
    
    try {
      // Get the current date
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      
      // Get all communities
      const communitiesRef = collection(db, 'customers', user.uid, 'communities');
      const communitiesSnapshot = await getDocs(communitiesRef);
      
      // Get today's day of the week (0-6, 0 is Sunday)
      const dayOfWeek = today.getDay();
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const todayString = daysOfWeek[dayOfWeek];
      
      // Check for communities that should have been serviced today
      for (const communityDoc of communitiesSnapshot.docs) {
        const communityData = communityDoc.data();
        
        // Skip if not active or doesn't have service days
        if (!communityData.isActive || !communityData.serviceDays) continue;
        
        // Check if today is a service day
        if (communityData.serviceDays.includes(todayString)) {
          // Check if we have a shift record for today
          const shiftsRef = collection(db, 'customers', user.uid, 'shifts');
          const q = query(
            shiftsRef,
            where('communityId', '==', communityDoc.id),
            where('date', '==', today.toISOString().split('T')[0])
          );
          
          const shiftsSnapshot = await getDocs(q);
          
          // If no shifts found and it's past 8 PM, create a missed shift notification
          if (shiftsSnapshot.empty && now.getHours() >= 20) {
            // Check if a notification already exists for this missed shift
            const existingNotification = notifications.find(
              n => n.type === 'missedShift' && 
                  n.communityId === communityDoc.id && 
                  new Date(n.timestamp).toDateString() === today.toDateString()
            );
            
            if (!existingNotification) {
              // Create a new notification
              await createNotification({
                type: 'missedShift',
                title: 'Missed Shift',
                message: `No service recorded today for ${communityData.propertyName}`,
                timestamp: now,
                read: false,
                seen: false,
                routeUrl: '/tracking',
                communityId: communityDoc.id,
                date: today.toISOString().split('T')[0]
              });
            }
          }
        }
      }
    } catch (err) {
      console.error('Error checking for missed shifts:', err);
    }
  };

  // Function to check for employee requests
  const checkEmployeeRequests = async (since) => {
    if (!user) return;
    
    try {
      // Get requests with status "pending"
      const requestsRef = collection(db, 'customers', user.uid, 'requests');
      const requestsSnapshot = await getDocs(requestsRef);
      
      // For each document, check pending requests
      for (const requestDoc of requestsSnapshot.docs) {
        const requestData = requestDoc.data();
        
        if (requestData.requests && Array.isArray(requestData.requests)) {
          // Filter for pending requests created since the given time
          const pendingRequests = requestData.requests.filter(req => 
            req.status === 'pending' && 
            req.createdAt >= since
          );
          
          // For each pending request, create a notification if it doesn't exist
          for (const request of pendingRequests) {
            // Check if a notification already exists for this request
            const existingNotification = notifications.find(
              n => n.type === 'request' && n.requestId === request.id
            );
            
            if (!existingNotification) {
              // Get employee name
              let employeeName = 'An employee';
              if (request.userId) {
                const employeeDoc = await getDoc(doc(db, 'customers', user.uid, 'team', request.userId));
                if (employeeDoc.exists()) {
                  const employeeData = employeeDoc.data();
                  employeeName = `${employeeData.firstName} ${employeeData.lastName}`;
                }
              }
              
              // Create a new notification
              await createNotification({
                type: 'request',
                title: 'New Employee Request',
                message: `${employeeName} submitted a ${request.requestType} request`,
                timestamp: request.createdAt,
                read: false,
                seen: false,
                routeUrl: '/requests',
                requestId: request.id
              });
            }
          }
        }
      }
    } catch (err) {
      console.error('Error checking for employee requests:', err);
    }
  };

  // Function to create a new notification
  const createNotification = async (notificationData) => {
    if (!user) return;
    
    try {
      // Add to Firestore
      const notificationsRef = collection(db, 'customers', user.uid, 'notifications');
      const notificationRef = doc(notificationsRef);
      
      await updateDoc(notificationRef, {
        ...notificationData,
        timestamp: notificationData.timestamp || new Date(),
        seen: notificationData.seen !== undefined ? notificationData.seen : false,
        read: notificationData.read !== undefined ? notificationData.read : false
      });
      
      // No need to update local state as the onSnapshot will handle it
      
      return { success: true, id: notificationRef.id };
    } catch (err) {
      console.error('Error creating notification:', err);
      return { success: false, error: err.message };
    }
  };

  /**
   * Mark a notification as read
   */
  const markAsRead = async (notificationId) => {
    if (!user) return;
    
    try {
      const notificationRef = doc(db, 'customers', user.uid, 'notifications', notificationId);
      
      // Update in Firestore
      await updateDoc(notificationRef, {
        read: true,
        seen: true // Always mark as seen when marking as read
      });
      
      // Update local state
      setNotifications(prev => 
        prev.map(n => 
          n.id === notificationId 
            ? { ...n, read: true, seen: true } 
            : n
        )
      );
      
      // Update counts
      const updatedUnreadCount = notifications.filter(n => n.id !== notificationId && !n.read).length;
      setUnreadCount(updatedUnreadCount);
      
      const updatedUnseenCount = notifications.filter(n => n.id !== notificationId && !n.seen).length;
      setUnseenCount(updatedUnseenCount);
      
      return true;
    } catch (err) {
      console.error('Error marking notification as read:', err);
      return false;
    }
  };
  
  /**
   * Mark a notification as seen (but not necessarily read)
   */
  const markAsSeen = async (notificationId) => {
    if (!user) return;
    
    try {
      const notificationRef = doc(db, 'customers', user.uid, 'notifications', notificationId);
      
      // Update in Firestore
      await updateDoc(notificationRef, {
        seen: true
      });
      
      // Update local state
      setNotifications(prev => 
        prev.map(n => 
          n.id === notificationId 
            ? { ...n, seen: true } 
            : n
        )
      );
      
      // Update count
      const updatedUnseenCount = notifications.filter(n => n.id !== notificationId && !n.seen).length;
      setUnseenCount(updatedUnseenCount);
      
      return true;
    } catch (err) {
      console.error('Error marking notification as seen:', err);
      return false;
    }
  };
  
  /**
   * Mark all notifications as read
   */
  const markAllAsRead = async () => {
    if (!user) return;
    
    try {
      const batch = writeBatch(db);
      const unreadNotifications = notifications.filter(n => !n.read);
      
      if (unreadNotifications.length === 0) {
        return true;
      }
      
      // Add all updates to the batch
      unreadNotifications.forEach(notification => {
        const notificationRef = doc(db, 'customers', user.uid, 'notifications', notification.id);
        batch.update(notificationRef, { 
          read: true,
          seen: true
        });
      });
      
      // Commit the batch
      await batch.commit();
      
      // Update local state
      setNotifications(prev => 
        prev.map(n => ({ ...n, read: true, seen: true }))
      );
      
      // Update counts
      setUnreadCount(0);
      setUnseenCount(0);
      
      return true;
    } catch (err) {
      console.error('Error marking all notifications as read:', err);
      return false;
    }
  };
  
  /**
   * Mark all notifications as seen
   */
  const markAllAsSeen = async () => {
    if (!user) return;
    
    try {
      const batch = writeBatch(db);
      const unseenNotifications = notifications.filter(n => !n.seen);
      
      if (unseenNotifications.length === 0) {
        return true;
      }
      
      // Add all updates to the batch
      unseenNotifications.forEach(notification => {
        const notificationRef = doc(db, 'customers', user.uid, 'notifications', notification.id);
        batch.update(notificationRef, { seen: true });
      });
      
      // Commit the batch
      await batch.commit();
      
      // Update local state
      setNotifications(prev => 
        prev.map(n => ({ ...n, seen: true }))
      );
      
      // Update count
      setUnseenCount(0);
      
      return true;
    } catch (err) {
      console.error('Error marking all notifications as seen:', err);
      setError(err.message);
      return false;
    }
  };

  // Provide the notification context
  return (
    <NotificationContext.Provider
      value={{
        notifications,
        unreadCount,
        unseenCount,
        loading,
        error,
        notificationSettings,
        updateNotificationSettings,
        markAsRead,
        markAsSeen,
        markAllAsRead,
        markAllAsSeen
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider; 