// src/App.js

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { TeamProvider } from './context/TeamContext';
import NotificationProvider from './context/NotificationContext';
import ErrorBoundary from './components/ErrorBoundary';

/**
 * Main App component
 * @param {Object} props - Component props
 * @param {Object} props.user - Current user object
 * @param {boolean} props.onboardingComplete - Whether onboarding is complete
 * @param {boolean} props.planSelected - Whether a subscription plan is selected
 * @param {boolean} props.isTeamMember - Whether the user is a team member
 * @returns {React.ReactNode} - App component
 */
const App = ({ user, onboardingComplete, planSelected, isTeamMember }) => {
  // Only wrap with providers when user is logged in and onboarding is complete
  const shouldUseProviders = user && onboardingComplete;
  
  return (
    <Router>
      <ErrorBoundary>
        {shouldUseProviders ? (
          <NotificationProvider>
            <TeamProvider>
              <AppRoutes 
                user={user} 
                onboardingComplete={onboardingComplete} 
                planSelected={planSelected} 
                isTeamMember={isTeamMember} 
              />
            </TeamProvider>
          </NotificationProvider>
        ) : (
          <AppRoutes 
            user={user} 
            onboardingComplete={onboardingComplete} 
            planSelected={planSelected} 
            isTeamMember={isTeamMember} 
          />
        )}
      </ErrorBoundary>
    </Router>
  );
};

export default App;
