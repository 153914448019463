import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import './MobileHeader.css';
import NavIcon from '../../assets/nav.svg';
import DemoLogo from '../../assets/valet-hero-logo-white.svg';
import CommunitiesIcon from '../../assets/icons/community.svg';
import TeamIcon from '../../assets/icons/team.svg';
import TrackingIcon from '../../assets/icons/tracking.svg';
import ScheduleIcon from '../../assets/icons/calendar.svg';
import SettingsIcon from '../../assets/icons/support.svg';
import LogoutIcon from '../../assets/icons/logout.svg';
import { useNotification } from '../../context/NotificationContext';
import NeonBellIcon from '../../assets/neon-bell.svg';

const MobileHeader = ({ title }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [communities, setCommunities] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const auth = getAuth();
    const user = auth.currentUser;
    const { unseenCount, markAllAsSeen } = useNotification();

    React.useEffect(() => {
        const fetchData = async () => {
            if (user) {
                const teamSnapshot = await getDocs(collection(db, 'customers', user.uid, 'team'));
                const communitiesSnapshot = await getDocs(collection(db, 'customers', user.uid, 'communities'));
                
                setTeamMembers(teamSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), type: 'team' })));
                setCommunities(communitiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), type: 'community' })));
            }
        };
        fetchData();
    }, [user]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value) {
            const results = [...teamMembers, ...communities].filter(item =>
                item.firstName?.toLowerCase().includes(e.target.value.toLowerCase()) ||
                item.lastName?.toLowerCase().includes(e.target.value.toLowerCase()) ||
                item.propertyName?.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }
    };

    const handleResultClick = (item) => {
        if (item.type === 'team') {
            navigate(`/team/${item.id}`);
        } else {
            navigate(`/communities/${item.id}`);
        }
        setSearchTerm('');
        setSearchResults([]);
        setIsMenuOpen(false);
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            window.location.href = '/login';
        } catch (error) {
            console.error('Logout Error:', error);
        }
    };

    const handleNotificationsClick = async () => {
        await markAllAsSeen();
        navigate('/notifications');
        setIsMenuOpen(false);
    };

    return (
        <div className="mobile-header">
            <div className="mobile-header-top">
                <Link to="/">
                    <img src={DemoLogo} alt="Logo" className="mobile-logo" />
                </Link>
                <h1 className="mobile-title">{title}</h1>
                <button className="menu-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <img src={NavIcon} alt="Menu" />
                </button>
            </div>

            {isMenuOpen && (
                <div className="mobile-menu">
                    <div className="mobile-search-container">
                        <input 
                            type="text" 
                            placeholder="Search team members or communities..." 
                            className="mobile-search-bar" 
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        {searchResults.length > 0 && (
                            <div className="mobile-search-results">
                                {searchResults.map((item) => (
                                    <div key={item.id} className="mobile-search-result-item" onClick={() => handleResultClick(item)}>
                                        {item.type === 'team' ? (
                                            <span>{`${item.firstName} ${item.lastName}`}</span>
                                        ) : (
                                            <span>{item.propertyName}</span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="mobile-nav-links">
                        <Link to="/communities" className={`mobile-nav-link ${location.pathname === "/communities" ? "selected" : ""}`} onClick={() => setIsMenuOpen(false)}>
                            <img src={CommunitiesIcon} alt="Communities" className="mobile-nav-icon" />
                            <span>Communities</span>
                        </Link>
                        <Link to="/team" className={`mobile-nav-link ${location.pathname === "/team" ? "selected" : ""}`} onClick={() => setIsMenuOpen(false)}>
                            <img src={TeamIcon} alt="Team" className="mobile-nav-icon" />
                            <span>Team</span>
                        </Link>
                        <Link to="/tracking" className={`mobile-nav-link ${location.pathname === "/tracking" ? "selected" : ""}`} onClick={() => setIsMenuOpen(false)}>
                            <img src={TrackingIcon} alt="Tracking" className="mobile-nav-icon" />
                            <span>Tracking</span>
                        </Link>
                        <Link to="/schedule" className={`mobile-nav-link ${location.pathname === "/schedule" ? "selected" : ""}`} onClick={() => setIsMenuOpen(false)}>
                            <img src={ScheduleIcon} alt="Schedule" className="mobile-nav-icon" />
                            <span>Schedule</span>
                        </Link>
                        <div 
                            className={`mobile-nav-link ${location.pathname === "/notifications" ? "selected" : ""}`} 
                            onClick={handleNotificationsClick}
                        >
                            <div className="mobile-nav-icon-container">
                                <img src={NeonBellIcon} alt="Notifications" className="mobile-nav-icon" />
                                {unseenCount > 0 && <span className="mobile-notification-badge">{unseenCount}</span>}
                            </div>
                            <span>Notifications</span>
                        </div>
                        <Link to="/settings" className="mobile-nav-link" onClick={() => setIsMenuOpen(false)}>
                            <img src={SettingsIcon} alt="Settings" className="mobile-nav-icon" />
                            <span>Settings</span>
                        </Link>
                        <div className="mobile-nav-link" onClick={handleLogout}>
                            <img src={LogoutIcon} alt="Logout" className="mobile-nav-icon" />
                            <span>Logout</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileHeader;