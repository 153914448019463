import React, { Component } from 'react';

/**
 * ErrorBoundary component to catch JavaScript errors in child components
 * and display a fallback UI instead of crashing the whole app
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  /**
   * Update state when an error occurs
   * @param {Error} error - The error that was thrown
   * @returns {Object} - Updated state with error information
   */
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  /**
   * Catch errors in child components
   * @param {Error} error - The error that was thrown
   * @param {Object} errorInfo - Information about the error including component stack
   */
  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    
    // You could also log to an error reporting service here
    // e.g., Sentry, LogRocket, etc.
  }

  /**
   * Reset the error state
   */
  handleReset = () => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null
    });
  };

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <div style={styles.container}>
          <div style={styles.errorCard}>
            <h2 style={styles.heading}>Something went wrong</h2>
            <p style={styles.message}>
              We apologize for the inconvenience. An error has occurred in this section of the application.
            </p>
            
            {this.props.showDetails && (
              <div style={styles.details}>
                <p style={styles.errorName}>{this.state.error && this.state.error.toString()}</p>
                {this.state.errorInfo && (
                  <details style={styles.componentStack}>
                    <summary>Component Stack</summary>
                    <pre>{this.state.errorInfo.componentStack}</pre>
                  </details>
                )}
              </div>
            )}
            
            <div style={styles.actions}>
              <button 
                onClick={this.handleReset}
                style={styles.resetButton}
              >
                Try Again
              </button>
              
              <button 
                onClick={() => window.location.reload()}
                style={styles.reloadButton}
              >
                Reload Page
              </button>
            </div>
          </div>
        </div>
      );
    }

    // If no error, render children normally
    return this.props.children;
  }
}

const styles = {
  container: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '300px'
  },
  errorCard: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    maxWidth: '600px',
    width: '100%',
    textAlign: 'center'
  },
  heading: {
    color: '#e53935',
    marginBottom: '16px',
    fontWeight: '500',
    fontSize: '24px'
  },
  message: {
    fontSize: '16px',
    color: '#333',
    marginBottom: '20px',
    lineHeight: '1.5'
  },
  details: {
    textAlign: 'left',
    marginBottom: '20px',
    padding: '12px',
    backgroundColor: '#f5f5f5',
    borderRadius: '4px',
    fontSize: '14px'
  },
  errorName: {
    fontWeight: 'bold',
    color: '#e53935',
    marginBottom: '8px'
  },
  componentStack: {
    marginTop: '8px',
    whiteSpace: 'pre-wrap',
    overflow: 'auto',
    maxHeight: '200px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
    marginTop: '24px'
  },
  resetButton: {
    backgroundColor: '#C6FF00',
    color: '#000',
    border: 'none',
    borderRadius: '4px',
    padding: '10px 16px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.2s'
  },
  reloadButton: {
    backgroundColor: '#f5f5f5',
    color: '#333',
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '10px 16px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.2s'
  }
};

export default ErrorBoundary; 