import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

/**
 * Protected Route Component
 * @param {Object} props - Component props
 * @param {boolean} props.isAuthenticated - Whether the user is authenticated
 * @param {boolean} props.isAllowed - Whether the user is allowed to access the route
 * @param {React.ReactNode} props.redirectPath - Path to redirect to if not allowed
 * @param {React.ReactNode} props.children - Child components
 * @returns {React.ReactNode} - Protected route
 */
const ProtectedRoute = ({ 
  isAuthenticated,
  isAllowed,
  redirectPath = '/login',
  children
}) => {
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute; 