// src/components/nav/SideNav.js

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import DemoLogo from '../../assets/valet-hero-logo-white.svg';
import HomeIcon from '../../assets/icons/home5.svg';
import CommunitiesIcon from '../../assets/icons/community.svg';
import TeamIcon from '../../assets/icons/team.svg';
import TrackingIcon from '../../assets/icons/tracking.svg';
import ScheduleIcon from '../../assets/icons/calendar.svg';
import SettingsIcon from '../../assets/icons/support.svg';
import LogoutIcon from '../../assets/icons/logout.svg';
import { getAuth, signOut } from 'firebase/auth';
import './SideNav.css';

const SideNav = () => {
  const location = useLocation();
  const auth = getAuth();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.href = '/login';
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  return (
    <div className="side-nav">
      <div className="icon-logo-container">
        <a href="/" className="logo-link">
          <img src={DemoLogo} alt='demo' className="icon-logo" />
        </a>
      </div>
      {/* nav Links */}
      <div className="nav-links">
        <Link to="/" className={`nav-link ${location.pathname === "/" ? "selected" : ""}`}>
          <img src={HomeIcon} alt='Home' className="nav-icon" />
          <span>Home</span>
        </Link>
        <Link to="/communities" className={`nav-link ${location.pathname === "/communities" ? "selected" : ""}`}>
          <img src={CommunitiesIcon} alt='Communities' className="nav-icon" />
          <span>Communities</span>
        </Link>
        <Link to="/team" className={`nav-link ${location.pathname === "/team" ? "selected" : ""}`}>
          <img src={TeamIcon} alt='Team' className="nav-icon" />
          <span>Team</span>
        </Link>
        <Link to="/tracking" className={`nav-link ${location.pathname === "/tracking" ? "selected" : ""}`}>
          <img src={TrackingIcon} alt='Tracking' className="nav-icon" />
          <span>Tracking</span>
        </Link>
        <Link to="/schedule" className={`nav-link ${location.pathname === "/schedule" ? "selected" : ""}`}>
          <img src={ScheduleIcon} alt='Schedule' className="nav-icon" />
          <span>Schedule</span>
        </Link>
      </div>
      <div className="support-logout">
        <Link to="/settings" className="nav-link">
          <img src={SettingsIcon} alt='Settings' className="nav-icon" />
          <span>Settings</span>
        </Link>
        <Link className="nav-link" onClick={handleLogout}>
          <img src={LogoutIcon} alt='Logout' className="nav-icon" />
          <span>Logout</span>
        </Link>
      </div>
    </div>
  );
}

export default SideNav;
