import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, collection, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

const useUserPlan = () => {
  const [userPlan, setUserPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const fetchUserPlan = async () => {
      try {
        console.log('=== Starting Plan Determination Process ===');
        setLoading(true);
        const user = auth.currentUser;
        
        if (!user) {
          console.log('No user found, setting plan to null');
          setUserPlan(null);
          setLoading(false);
          return;
        }

        console.log('Fetching customer document for user:', user.uid);
        // Get customer document
        const customerDoc = await getDoc(doc(db, 'customers', user.uid));
        if (!customerDoc.exists()) {
          console.log('No customer document found, using default free plan');
          // If customer doc doesn't exist, use default free plan (1 user)
          setUserPlan({
            maxTeam: 1,
            maxCommunities: 2,
            admin: 1,
            name: 'one',
            rank: 1,
            priceMonthly: 0,
            subscription: null
          });
          setLoading(false);
          return;
        }
        
        const customerData = customerDoc.data();
        console.log('Customer document data:', customerData);

        // Get active subscriptions
        console.log('Fetching active subscriptions...');
        const subscriptionsRef = collection(db, 'customers', user.uid, 'subscriptions');
        const subscriptionsSnapshot = await getDocs(subscriptionsRef);
        
        console.log('Found subscriptions:', subscriptionsSnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));

        const activeSubscription = subscriptionsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .find(sub => 
            sub.status === 'active' && 
            sub.current_period_end && 
            new Date(sub.current_period_end.seconds * 1000) > new Date()
          );

        console.log('Active subscription found:', activeSubscription);
        
        // Check if subscription quantity exists first - THIS IS THE PRIORITY
        if (activeSubscription?.items?.[0]?.quantity) {
          const quantity = activeSubscription.items[0].quantity;
          console.log('Found subscription quantity:', quantity);
          
          // Get all plans to find best match
          console.log('Fetching all plans to find match...');
          const plansRef = collection(db, 'plans');
          const plansSnapshot = await getDocs(plansRef);
          const allPlans = plansSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          console.log('All available plans:', allPlans);
          
          // First try to find a plan with matching rank to the quantity + 1 (for free admin)
          const exactRankMatch = allPlans.find(plan => plan.rank === quantity + 1);
          
          if (exactRankMatch) {
            console.log('Found exact rank match:', exactRankMatch);
            
            // Update planId if it doesn't match
            if (customerData.planId !== exactRankMatch.id) {
              console.log(`Updating planId from ${customerData.planId} to ${exactRankMatch.id}`);
              await updateDoc(doc(db, 'customers', user.uid), {
                planId: exactRankMatch.id,
                lastModified: new Date()
              });
            }
            
            setUserPlan({
              ...exactRankMatch,
              subscription: activeSubscription
            });
            setLoading(false);
            return;
          }
          
          // If no rank match, try name match as fallback
          console.log('No exact rank match, trying name match...');
          const planName = (quantity + 1).toString();
          const planDocRef = doc(db, 'plans', planName);
          const planDoc = await getDoc(planDocRef);
          
          if (planDoc.exists()) {
            const planData = planDoc.data();
            console.log('Found plan by name match:', planData);
            
            // Update planId if it doesn't match
            if (customerData.planId !== planName) {
              console.log(`Updating planId from ${customerData.planId} to ${planName}`);
              await updateDoc(doc(db, 'customers', user.uid), {
                planId: planName,
                lastModified: new Date()
              });
            }
            
            setUserPlan({
              ...planData,
              subscription: activeSubscription
            });
            setLoading(false);
            return;
          }
          
          // Find the highest plan that does not exceed the quantity + 1
          console.log('No name match, finding highest eligible plan...');
          const eligiblePlans = allPlans
            .filter(plan => plan.rank && plan.rank <= (quantity + 1))
            .sort((a, b) => b.rank - a.rank);
          
          console.log('Eligible plans:', eligiblePlans);
          
          if (eligiblePlans.length > 0) {
            const bestPlan = eligiblePlans[0];
            console.log('Selected best matching plan:', bestPlan);
            
            // Update planId if it doesn't match
            if (customerData.planId !== bestPlan.id) {
              console.log(`Updating planId from ${customerData.planId} to ${bestPlan.id}`);
              await updateDoc(doc(db, 'customers', user.uid), {
                planId: bestPlan.id,
                lastModified: new Date()
              });
            }
            
            setUserPlan({
              ...bestPlan,
              subscription: activeSubscription
            });
            setLoading(false);
            return;
          }
          
          // If no plan fits, use the quantity + 1 directly
          console.log('No matching plan found, creating custom plan with quantity:', quantity + 1);
          const customPlanId = `custom-${quantity + 1}`;
          
          // Update planId if it doesn't match
          if (customerData.planId !== customPlanId) {
            console.log(`Updating planId from ${customerData.planId} to ${customPlanId}`);
            await updateDoc(doc(db, 'customers', user.uid), {
              planId: customPlanId,
              lastModified: new Date()
            });
          }
          
          setUserPlan({
            maxTeam: quantity + 1,
            maxCommunities: Math.max((quantity + 1) * 2, 5),
            admin: Math.max(Math.floor((quantity + 1) / 10) + 1, 1),
            name: customPlanId,
            rank: quantity + 1,
            priceMonthly: activeSubscription.items[0].price?.unit_amount ? 
              activeSubscription.items[0].price.unit_amount / 100 : 0,
            subscription: activeSubscription
          });
          setLoading(false);
          return;
        }
        
        // If no subscription quantity but there's a planId in customer document
        if (customerData.planId) {
          console.log('No subscription quantity, but found planId:', customerData.planId);
          const planDocRef = doc(db, 'plans', customerData.planId);
          const planDoc = await getDoc(planDocRef);
          
          if (planDoc.exists()) {
            const planData = planDoc.data();
            console.log('Found plan from planId:', planData);
            setUserPlan({
              ...planData,
              subscription: activeSubscription || null
            });
            setLoading(false);
            return;
          }
        }

        // If no subscription quantity and no planId, use free plan
        console.log('No subscription quantity or planId found, using free plan');
        
        // Update planId to 'one' if it's not already
        if (customerData.planId !== 'one') {
          console.log(`Updating planId from ${customerData.planId} to 'one'`);
          await updateDoc(doc(db, 'customers', user.uid), {
            planId: 'one',
            lastModified: new Date()
          });
        }
        
        setUserPlan({
          maxTeam: 1,
          maxCommunities: 2,
          admin: 1,
          name: 'one',
          rank: 1,
          priceMonthly: 0,
          subscription: null
        });
        
      } catch (err) {
        console.error('Error in plan determination process:', err);
        setError(err);
        // Set default free plan values on error
        setUserPlan({
          maxTeam: 1,
          maxCommunities: 2,
          admin: 1,
          name: 'one',
          rank: 1,
          priceMonthly: 0,
          subscription: null
        });
      } finally {
        console.log('=== Final Plan Set ===', userPlan);
        setLoading(false);
      }
    };

    fetchUserPlan();
  }, [auth.currentUser]);

  return { userPlan, loading, error };
};

export default useUserPlan;
