// src/pages/Dashboard.js

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SideNav from '../components/nav/SIdeNav';
import DashboardHeader from '../components/nav/DashboardHeader';
import './Dashboard.css';
import { Outlet } from 'react-router-dom';
import MobileHeader from '../components/nav/MobileHeader';

const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const [title, setTitle] = useState('Dashboard');

  // Use effect to update title when location changes
  useEffect(() => {
    // Determine the title based on the current route
    switch (location.pathname) {
      case '/':
        setTitle('Home');
        break;
      case '/communities':
        setTitle('Communities');
        break;
      case '/team':
        setTitle('Team');
        break;
      case '/tracking':
        setTitle('Tracking');
        break;
      case '/schedule':
        setTitle('Schedule');
        break;
      case '/settings':
        setTitle('Settings');
        break;
      case '/shift-report':
        setTitle('Shift Report');
        break;
      case '/clock-in-outs':
        setTitle('Clock In-Outs');
        break;
      case '/checkpoints':
        setTitle('Check Points');
        break;
      case '/units':
        setTitle('Units');
        break;
      case '/violations':
        setTitle('Violations');
        break;
      case '/notifications':
        setTitle('All Notifications');
        break;
      default:
        if (location.pathname.startsWith('/communities/')) {
          setTitle('Community Details');
        } else if (location.pathname.startsWith('/team/')) {
          setTitle('Team Member Details');
        } else {
          setTitle('Dashboard');
        }
    }
  }, [location.pathname]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="dashboard">
      <SideNav />
      <MobileHeader title={title} />
      <div className="dashboard-content">
        <DashboardHeader title={title} onUpgradeClick={handleOpenModal} />
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
