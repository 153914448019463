import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { getAuth } from 'firebase/auth';
import './DashboardHeader.css';
import HelpIcon from '../../assets/help.svg';
import NeonBellIcon from '../../assets/neon-bell.svg';
import { useNotification } from '../../context/NotificationContext';
import { format } from 'date-fns';

const DashboardHeader = ({ title }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  
  // Get notifications from context
  const { 
    notifications, 
    unreadCount,
    unseenCount,
    markAsRead, 
    markAllAsRead,
    markAsSeen,
    markAllAsSeen
  } = useNotification();

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        const teamSnapshot = await getDocs(collection(db, 'customers', user.uid, 'team'));
        const communitiesSnapshot = await getDocs(collection(db, 'customers', user.uid, 'communities'));
        
        setTeamMembers(teamSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), type: 'team' })));
        setCommunities(communitiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), type: 'community' })));
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    if (searchTerm) {
      console.log('Search term:', searchTerm);
      console.log('Team members:', teamMembers);
      console.log('Communities:', communities);
      const results = [...teamMembers, ...communities].filter(item =>
        item.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.propertyName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      console.log('Search results:', results);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, teamMembers, communities]);

  // Close notifications dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleResultClick = (item) => {
    if (item.type === 'team') {
      navigate(`/team/${item.id}`);
    } else {
      navigate(`/communities/${item.id}`);
    }
    setSearchTerm('');
    setSearchResults([]);
  };

  const toggleNotifications = async () => {
    // When opening notifications dropdown, mark all as seen
    if (!showNotifications) {
      await markAllAsSeen();
    }
    setShowNotifications(!showNotifications);
  };

  const handleNotificationClick = async (notification) => {
    // Mark as read
    if (!notification.read) {
      await markAsRead(notification.id);
    }
    
    // Navigate to the specified route
    navigate(notification.routeUrl);
    
    // Close the dropdown
    setShowNotifications(false);
  };

  const handleViewAll = () => {
    // Simply navigate to the notifications page while preserving the Dashboard layout
    navigate('/notifications');
    setShowNotifications(false);
  };

  const handleMarkAllAsRead = async (e) => {
    e.stopPropagation();
    await markAllAsRead();
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'violation':
        return '⚠️';
      case 'contact':
        return '💬';
      case 'clockInOut':
        return '🕒';
      case 'missedShift':
        return '❌';
      case 'request':
        return '📋';
      default:
        return '📢';
    }
  };

  return (
    <div className="vh-header">
      <div className="vh-header-left">
        <h2 className="vh-page-title">{title}</h2>
      </div>
      <div className="vh-header-center">
        <div className="vh-search-container-header">
          <input 
            type="text" 
            placeholder="Search team members or communities..." 
            className="vh-search-input-header" 
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {searchResults.length > 0 && (
            <div className="vh-search-results">
              {searchResults.map((item) => (
                <div 
                  key={item.id} 
                  className="vh-search-result-item" 
                  onClick={() => handleResultClick(item)}
                >
                  {item.type === 'team' ? (
                    <span>{`${item.firstName} ${item.lastName}`}</span>
                  ) : (
                    <span>{item.propertyName}</span>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="vh-header-right">
        {/* Notification Icon with Badge */}
        <div className="vh-notification-container" ref={notificationRef}>
          <div className="vh-notification-icon" onClick={toggleNotifications}>
            <img src={NeonBellIcon} alt="Notifications" width="24" height="24" />
            {unseenCount > 0 && (
              <span className="vh-notification-badge">{unseenCount}</span>
            )}
          </div>
          
          {/* Notification Dropdown */}
          {showNotifications && (
            <div className="vh-notification-dropdown">
              <div className="vh-notification-header">
                <h3>Notifications</h3>
                {unreadCount > 0 && (
                  <button className="vh-mark-all-read" onClick={handleMarkAllAsRead}>
                    Mark all as read
                  </button>
                )}
              </div>
              
              <div className="vh-notification-list">
                {notifications.filter(n => !n.read).length > 0 ? (
                  // Show only unread notifications and limit to 5
                  notifications
                    .filter(n => !n.read)
                    .slice(0, 5)
                    .map((notification) => (
                      <div 
                        key={notification.id} 
                        className="vh-notification-item unread"
                        onClick={() => handleNotificationClick(notification)}
                      >
                        <div className="vh-notification-icon">
                          {getNotificationIcon(notification.type)}
                        </div>
                        <div className="vh-notification-content">
                          <h4 className="vh-notification-title">{notification.title}</h4>
                          <p className="vh-notification-message">{notification.message}</p>
                          <span className="vh-notification-time">
                            {format(notification.timestamp, 'MMM d, h:mm a')}
                          </span>
                        </div>
                        <div className="vh-notification-dot"></div>
                      </div>
                    ))
                ) : (
                  <div className="vh-no-notifications">
                    <p>No new notifications</p>
                  </div>
                )}
              </div>
              
              <div className="vh-notification-footer">
                <Link to="/notifications" className="vh-view-all-link" onClick={() => setShowNotifications(false)}>
                  View All Notifications
                </Link>
              </div>
            </div>
          )}
        </div>
        
        <a 
          href="https://valethero.org/software-walkthrough/" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <img src={HelpIcon} alt="Help" className="vh-help-icon" />
        </a>
      </div>
    </div>
  );
};

export default DashboardHeader;
