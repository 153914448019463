import React, { lazy } from 'react';

// Lazy load page components
const Login = lazy(() => import('../pages/Login'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Onboarding = lazy(() => import('../pages/Onboarding'));
const Pricing = lazy(() => import('../pages/Pricing'));
const Home = lazy(() => import('../pages/Home'));
const Communities = lazy(() => import('../pages/Communities'));
const CommunityProfile = lazy(() => import('../pages/CommunityProfile'));
const Team = lazy(() => import('../pages/Team'));
const TeamProfile = lazy(() => import('../pages/TeamProfile'));
const Tracking = lazy(() => import('../pages/Tracking'));
const Schedule = lazy(() => import('../pages/Schedule'));
const Settings = lazy(() => import('../pages/Settings'));
const PublicCommunityProfile = lazy(() => import('../pages/PublicCommunityProfile'));
const BintagRedirect = lazy(() => import('../components/BintagRedirect'));
const NotFound = lazy(() => import('../pages/NotFound'));

// Import report pages
const ShiftReports = lazy(() => import('../pages/masters/ShiftReport'));
const ClockInOuts = lazy(() => import('../pages/masters/ClockInOuts'));
const CheckPoint = lazy(() => import('../pages/masters/CheckPoint'));
const UnitsPage = lazy(() => import('../pages/masters/Units'));
const ViolationsPage = lazy(() => import('../pages/masters/ViolationsPage'));
const NotificationsPage = lazy(() => import('../pages/masters/NotificationsPage'));
const AllViolations = lazy(() => import('../pages/AllViolations'));
const AllServiceLogs = lazy(() => import('../pages/AllServiceLogs'));
const PropertyAdmin = lazy(() => import('../pages/PropertyAdmin'));
const BinTagsTable = lazy(() => import('../components/communities/BinTagsTable'));

// Define route configurations
export const publicRoutes = [
  {
    path: '/login',
    element: Login,
    exact: true,
  },
  {
    path: '/:businessName/:communityName',
    element: PublicCommunityProfile,
    exact: true,
  },
  {
    path: '/:businessName/:communityName/bintag/:bintagId',
    element: BintagRedirect,
    exact: true,
  },
];

export const onboardingRoutes = [
  {
    path: '/onboarding',
    element: Onboarding,
    exact: true,
  },
  {
    path: '/pricing',
    element: Pricing,
    exact: true,
  },
];

export const adminRoutes = [
  {
    path: '/:businessName/:communityName/admin',
    element: PropertyAdmin,
    exact: true,
  },
  {
    path: '/:businessName/:communityName/admin/violations',
    element: AllViolations,
    exact: true,
  },
  {
    path: '/:businessName/:communityName/admin/service-logs',
    element: AllServiceLogs,
    exact: true,
  },
];

export const dashboardRoutes = [
  {
    path: '/',
    element: Home,
    exact: true,
  },
  {
    path: '/communities',
    element: Communities,
    exact: true,
  },
  {
    path: '/communities/:propertyName',
    element: CommunityProfile,
    exact: true,
  },
  {
    path: '/team',
    element: Team,
    exact: true,
  },
  {
    path: '/team/:employeeId',
    element: TeamProfile,
    exact: true,
  },
  {
    path: '/tracking',
    element: Tracking,
    exact: true,
  },
  {
    path: '/schedule',
    element: Schedule,
    exact: true,
  },
  {
    path: '/settings',
    element: Settings,
    exact: true,
  },
  {
    path: '/shift-report',
    element: ShiftReports,
    exact: true,
  },
  {
    path: '/clock-in-outs',
    element: ClockInOuts,
    exact: true,
  },
  {
    path: '/checkpoints',
    element: CheckPoint,
    exact: true,
  },
  {
    path: '/units',
    element: UnitsPage,
    exact: true,
  },
  {
    path: '/violations',
    element: ViolationsPage,
    exact: true,
  },
  {
    path: '/notifications',
    element: NotificationsPage,
    exact: true,
  },
  {
    path: '/communities/:communityName/bintags',
    element: BinTagsTable,
    exact: true,
    props: true
  },
]; 