import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { publicRoutes, onboardingRoutes, adminRoutes, dashboardRoutes } from './routeConfig';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/NotFound';

/**
 * Main routing component for the application
 * @param {Object} props - Component props
 * @param {Object} props.user - Current user object
 * @param {boolean} props.onboardingComplete - Whether onboarding is complete
 * @param {boolean} props.planSelected - Whether a subscription plan is selected
 * @param {boolean} props.isTeamMember - Whether the user is a team member
 * @returns {React.ReactNode} - Main routes component
 */
const AppRoutes = ({ user, onboardingComplete, planSelected, isTeamMember }) => {
  return (
    <Suspense fallback={null}>
      <Routes>
        {/* Public Routes */}
        {publicRoutes.map((route, index) => (
          <Route 
            key={index}
            path={route.path} 
            element={
              route.path === '/login' 
                ? user 
                  ? <Navigate to={onboardingComplete ? "/" : "/onboarding"} />
                  : <route.element />
                : <route.element />
            } 
          />
        ))}

        {/* Onboarding Routes - protected, requires auth but not onboarding completion */}
        {onboardingRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <ProtectedRoute 
                isAuthenticated={!!user} 
                isAllowed={!isTeamMember}
                redirectPath="/login"
              >
                <route.element />
              </ProtectedRoute>
            }
          />
        ))}

        {/* Admin Routes - publicly accessible for property admins */}
        {adminRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<route.element />}
          />
        ))}

        {/* Dashboard Routes - protected, requires auth and onboarding completion */}
        <Route
          path="/"
          element={
            <ProtectedRoute 
              isAuthenticated={!!user} 
              isAllowed={onboardingComplete}
              redirectPath={user ? "/onboarding" : "/login"}
            >
              <Dashboard />
            </ProtectedRoute>
          }
        >
          {dashboardRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                route.props 
                  ? <route.element user={user} />
                  : <route.element />
              }
            />
          ))}
        </Route>

        {/* Catch-all route */}
        <Route 
          path="*" 
          element={
            user 
              ? <Navigate to={onboardingComplete ? "/" : "/onboarding"} />
              : <Navigate to="/login" />
          } 
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes; 