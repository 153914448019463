import { 
  collection, 
  getDocs, 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc, 
  deleteDoc, 
  writeBatch,
  serverTimestamp 
} from 'firebase/firestore';
import { db } from '../firebase';

/**
 * Toggle a team member's active status
 * @param {string} userId - Current user ID
 * @param {string} memberId - ID of the member to toggle
 * @param {boolean} currentActive - Current active status
 * @param {Array} teamMembers - All team members
 * @param {Array} activeCommunities - All active communities
 * @param {Object} userPlan - User's plan information
 * @returns {Promise<Object>} - Result with success status and updated members if successful
 */
export const toggleTeamMemberActive = async (
  userId, 
  memberId, 
  currentActive, 
  teamMembers, 
  activeCommunities, 
  userPlan
) => {
  try {
    // If trying to activate and we're at plan limit, prevent it
    if (!currentActive) {
      if (!userPlan) {
        return {
          success: false,
          error: "Cannot activate member. User plan information is not available."
        };
      }

      const maxTeamMembers = userPlan.maxTeam || 1;
      const activeTeamCount = teamMembers.filter(member => member.isActive !== false).length;
      const planLimitReached = activeTeamCount >= maxTeamMembers;
      
      console.log('Toggle team member check:', {
        activeTeamCount,
        maxTeamMembers,
        planLimitReached
      });
      
      if (planLimitReached) {
        return {
          success: false,
          error: `Cannot activate member. Your plan limit is ${maxTeamMembers} team members.`
        };
      }
    }

    // If deactivating, check for assignments first
    if (currentActive) {
      const member = teamMembers.find(m => m.id === memberId);
      if (member) {
        // Get all scheduled shifts
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const scheduleRef = collection(db, 'customers', userId, 'schedule');
        const scheduleSnapshot = await getDocs(scheduleRef);
        let scheduledShifts = [];
        
        for (const scheduleDoc of scheduleSnapshot.docs) {
          const scheduleDate = new Date(scheduleDoc.id);
          if (scheduleDate >= today) {
            const scheduleData = scheduleDoc.data();
            if (scheduleData.schedule && Array.isArray(scheduleData.schedule)) {
              const memberShifts = scheduleData.schedule.filter(change => change.newAgentId === memberId);
              if (memberShifts.length > 0) {
                scheduledShifts.push({
                  date: scheduleDoc.id,
                  shifts: memberShifts
                });
              }
            }
          }
        }

        // Get pending requests
        const requestsRef = collection(db, 'customers', userId, 'requests');
        const requestsSnapshot = await getDocs(requestsRef);
        let pendingRequests = [];
        
        for (const requestDoc of requestsSnapshot.docs) {
          const requestData = requestDoc.data();
          if (requestData.requests && Array.isArray(requestData.requests)) {
            const memberRequests = requestData.requests.filter(
              req => req.userId === memberId && req.status === 'pending'
            );
            if (memberRequests.length > 0) {
              pendingRequests.push(...memberRequests);
            }
          }
        }

        // Only show warning if there are assignments to clean up
        const hasAssignments = (
          (member.assignedCommunities && member.assignedCommunities.length > 0) ||
          scheduledShifts.length > 0 ||
          pendingRequests.length > 0
        );

        if (hasAssignments) {
          // Build warning message
          let warningMessage = 'Warning: Deactivating this team member will remove:\n\n';
          
          // Add regular assignments
          if (member.assignedCommunities && member.assignedCommunities.length > 0) {
            warningMessage += '• Regular Assignments:\n';
            member.assignedCommunities.forEach(community => {
              warningMessage += `  - ${community.name} (${community.days.join(', ')})\n`;
            });
            warningMessage += '\n';
          }

          // Add scheduled shifts
          if (scheduledShifts.length > 0) {
            warningMessage += '• Scheduled Shifts:\n';
            scheduledShifts.forEach(schedule => {
              const date = new Date(schedule.date).toLocaleDateString();
              schedule.shifts.forEach(shift => {
                const community = activeCommunities.find(c => c.id === shift.communityId);
                warningMessage += `  - ${community?.propertyName || 'Unknown Property'} on ${date}\n`;
              });
            });
            warningMessage += '\n';
          }

          // Add pending requests
          if (pendingRequests.length > 0) {
            warningMessage += '• Pending Requests:\n';
            pendingRequests.forEach(request => {
              const date = request.requestDate.toDate().toLocaleDateString();
              const community = activeCommunities.find(c => c.id === request.communityId);
              const requestType = request.requestType === 'requestOff' ? 'Time Off' : 'Shift';
              warningMessage += `  - ${requestType} request for ${community?.propertyName || 'Unknown Property'} on ${date}\n`;
            });
          }

          warningMessage += '\nAre you sure you want to proceed?';

          const confirmDeactivate = window.confirm(warningMessage);
          if (!confirmDeactivate) {
            return { success: false, canceled: true };
          }
        }
      }
    }

    const batch = writeBatch(db);
    
    // Update member's active status
    const memberRef = doc(db, 'customers', userId, 'team', memberId);
    batch.update(memberRef, { 
      isActive: !currentActive,
      lastModified: serverTimestamp()
    });

    if (currentActive) {
      // Remove from community assignments when deactivating
      const communitiesSnapshot = await getDocs(collection(db, 'customers', userId, 'communities'));
      
      for (const communityDoc of communitiesSnapshot.docs) {
        const communityData = communityDoc.data();
        const serviceDays = communityData.serviceDays || [];
        
        // Check and remove from assigned agents
        for (const day of serviceDays) {
          const agentRef = doc(db, 'customers', userId, 'communities', communityDoc.id, 'assignedAgents', day);
          const agentDoc = await getDoc(agentRef);
          
          if (agentDoc.exists() && agentDoc.data().memberId === memberId) {
            batch.delete(agentRef);
          }
        }

        // Remove from district manager role if assigned
        if (communityData.districtManagerId === memberId) {
          batch.update(doc(db, 'customers', userId, 'communities', communityDoc.id), {
            districtManagerId: null
          });
        }
      }

      // Remove from schedule assignments and handle requests
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // Handle schedule assignments
      const scheduleRef = collection(db, 'customers', userId, 'schedule');
      const scheduleSnapshot = await getDocs(scheduleRef);

      for (const scheduleDoc of scheduleSnapshot.docs) {
        const scheduleDate = new Date(scheduleDoc.id);
        if (scheduleDate >= today) {
          const scheduleData = scheduleDoc.data();
          if (scheduleData.schedule && Array.isArray(scheduleData.schedule)) {
            // Filter out any schedule entries for this member
            const updatedSchedule = scheduleData.schedule.map(change => {
              if (change.newAgentId === memberId) {
                return { ...change, newAgentId: null };
              }
              return change;
            });
            
            if (JSON.stringify(updatedSchedule) !== JSON.stringify(scheduleData.schedule)) {
              batch.update(scheduleDoc.ref, { 
                schedule: updatedSchedule,
                lastUpdated: serverTimestamp()
              });
            }
          }
        }
      }

      // Handle pending requests
      const requestsRef = collection(db, 'customers', userId, 'requests');
      const requestsSnapshot = await getDocs(requestsRef);
      const now = new Date().toISOString();

      for (const requestDoc of requestsSnapshot.docs) {
        const requestData = requestDoc.data();
        if (requestData.requests && Array.isArray(requestData.requests)) {
          const updatedRequests = requestData.requests.map(request => {
            if (request.userId === memberId && request.status === 'pending') {
              return {
                ...request,
                status: 'cancelled',
                cancelledAt: now,
                cancelledBy: userId,
                cancellationReason: 'Team member deactivated'
              };
            }
            return request;
          });

          if (JSON.stringify(updatedRequests) !== JSON.stringify(requestData.requests)) {
            batch.update(requestDoc.ref, { 
              requests: updatedRequests,
              lastUpdated: serverTimestamp()
            });
          }
        }
      }
    }

    await batch.commit();
    
    // Update team members state
    const updatedMembers = teamMembers.map(member => 
      member.id === memberId 
        ? { ...member, isActive: !currentActive, assignedCommunities: currentActive ? [] : member.assignedCommunities }
        : member
    );

    // Recalculate plan limits
    let newPlanLimitReached = false;
    let newExcessTeamMembers = 0;
    
    if (userPlan) {
      const maxTeamMembers = userPlan.maxTeam || 1;
      const activeTeamCount = updatedMembers.filter(member => member.isActive !== false).length;
      newPlanLimitReached = activeTeamCount >= maxTeamMembers;
      newExcessTeamMembers = Math.max(0, activeTeamCount - maxTeamMembers);
    }

    return {
      success: true,
      updatedMembers,
      planLimitReached: newPlanLimitReached,
      excessTeamMembers: newExcessTeamMembers
    };
    
  } catch (error) {
    console.error('Error toggling member status:', error);
    return { 
      success: false, 
      error: error.message || 'Error updating member status. Please try again.' 
    };
  }
};

/**
 * Check if a team member can be activated based on plan limits
 * @param {Array} teamMembers - All team members
 * @param {Object} userPlan - User's plan information
 * @returns {boolean} - Whether the plan limit has been reached
 */
export const checkTeamMemberPlanLimits = (teamMembers, userPlan) => {
  if (!userPlan) return true;
  
  const maxTeamMembers = userPlan.maxTeam || 1;
  const activeTeamCount = teamMembers.filter(member => member.isActive !== false).length;
  
  return activeTeamCount >= maxTeamMembers;
};

/**
 * Calculate excess team members over plan limit
 * @param {Array} teamMembers - All team members
 * @param {Object} userPlan - User's plan information
 * @returns {number} - Number of excess team members
 */
export const calculateExcessTeamMembers = (teamMembers, userPlan) => {
  if (!userPlan) return 0;
  
  const maxTeamMembers = userPlan.maxTeam || 1;
  const activeTeamCount = teamMembers.filter(member => member.isActive !== false).length;
  
  return Math.max(0, activeTeamCount - maxTeamMembers);
}; 