import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import useUserPlan from '../hooks/useUserPlan';
import useTeamMembers from '../hooks/useTeamMembers';
import { toggleTeamMemberActive } from '../utils/teamOperations';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

// Create the context
export const TeamContext = createContext();

/**
 * Custom hook to use the team context
 * @returns {Object} The team context value
 */
export const useTeam = () => {
  const context = useContext(TeamContext);
  if (!context) {
    throw new Error('useTeam must be used within a TeamProvider');
  }
  return context;
};

/**
 * TeamProvider component to wrap components needing access to team data
 * @param {Object} props Component props
 * @param {React.ReactNode} props.children Child components 
 * @returns {React.ReactNode} Provider component
 */
export const TeamProvider = ({ children }) => {
  const auth = getAuth();
  const user = auth.currentUser;
  const { userPlan, loading: planLoading } = useUserPlan();
  
  const {
    teamMembers,
    loading: membersLoading,
    error: membersError,
    planLimitReached,
    excessTeamMembers,
    fetchTeamMembers,
    addTeamMember,
    updateTeamMember,
    deleteTeamMember,
    setTeamMembers,
    setPlanLimitReached,
    setExcessTeamMembers
  } = useTeamMembers(userPlan);

  const [activeCommunities, setActiveCommunities] = useState([]);
  const [communitiesLoading, setCommunitiesLoading] = useState(true);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [tierFilter, setTierFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectAll, setSelectAll] = useState(false);
  
  // Fetch active communities
  useEffect(() => {
    const fetchCommunities = async () => {
      if (user) {
        try {
          setCommunitiesLoading(true);
          const communitiesRef = collection(db, 'customers', user.uid, 'communities');
          const communitiesSnapshot = await getDocs(communitiesRef);
          const communities = communitiesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setActiveCommunities(communities);
        } catch (error) {
          console.error('Error fetching communities:', error);
        } finally {
          setCommunitiesLoading(false);
        }
      }
    };

    fetchCommunities();
  }, [user]);

  // Handle toggling a team member's active status
  const handleToggleActive = async (memberId, currentActive) => {
    if (!user) return;
    
    const result = await toggleTeamMemberActive(
      user.uid,
      memberId,
      currentActive,
      teamMembers,
      activeCommunities,
      userPlan
    );
    
    if (result.success) {
      // Update state with the new data
      setTeamMembers(result.updatedMembers);
      setPlanLimitReached(result.planLimitReached);
      setExcessTeamMembers(result.excessTeamMembers);
    } else if (result.error && !result.canceled) {
      // If there was an error and it wasn't due to user cancellation
      alert(result.error);
    }
  };

  // Handle selecting a team member
  const handleSelectMember = (memberId) => {
    setSelectedMembers(prev => {
      if (prev.includes(memberId)) {
        return prev.filter(id => id !== memberId);
      } else {
        return [...prev, memberId];
      }
    });
  };

  // Handle select all toggle
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allVisibleMemberIds = getFilteredTeamMembers().map(member => member.id);
      setSelectedMembers(allVisibleMemberIds);
      setSelectAll(true);
    } else {
      setSelectedMembers([]);
      setSelectAll(false);
    }
  };

  // Get filtered and sorted team members
  const getFilteredTeamMembers = () => {
    return teamMembers.filter(member => {
      // Apply tier filter if set
      if (tierFilter && member.tier !== tierFilter) {
        return false;
      }
      
      // Apply search filter
      return (
        `${member.firstName} ${member.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }).sort((a, b) => {
      // Apply sorting if configured
      if (!sortConfig.key) return 0;
      
      let aValue, bValue;
      
      if (sortConfig.key === 'fullName') {
        aValue = `${a.firstName} ${a.lastName}`.toLowerCase();
        bValue = `${b.firstName} ${b.lastName}`.toLowerCase();
      } else if (sortConfig.key === 'lastActive') {
        aValue = a.lastActive ? a.lastActive.toDate().getTime() : 0;
        bValue = b.lastActive ? b.lastActive.toDate().getTime() : 0;
      } else {
        aValue = a[sortConfig.key];
        bValue = b[sortConfig.key];
      }
      
      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  // Handle sorting
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Clear selections
  const clearSelections = () => {
    setSelectedMembers([]);
    setSelectAll(false);
  };

  const loading = membersLoading || planLoading || communitiesLoading;
  
  // Context value to be provided
  const value = {
    teamMembers,
    filteredMembers: getFilteredTeamMembers(),
    loading,
    error: membersError,
    activeCommunities,
    planLimitReached,
    excessTeamMembers,
    selectedMembers,
    searchTerm,
    tierFilter,
    sortConfig,
    selectAll,
    userPlan,
    
    // Methods
    fetchTeamMembers,
    addTeamMember,
    updateTeamMember,
    deleteTeamMember,
    handleToggleActive,
    handleSelectMember,
    handleSelectAll,
    setSearchTerm,
    setTierFilter,
    handleSort,
    clearSelections
  };
  
  return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>;
};

export default TeamProvider; 